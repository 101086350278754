import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import SEO from '../components/seo'

import * as loadScript from "simple-load-script"
import "babel-polyfill"

import {
  Header,
  Preloader,
  Sidenav,
  About,
  Resume,
  Hero,
  Contact,
  Blog
} from "../components"

const IndexPage = ({
  location,
  data: {
    prismicAbout,
    prismicResume,
    prismicSidenav,
    prismicHero,
    allPrismicPost,
    prismicContact,
  },
  pageContext: { locale },
}) => {
  useEffect(() => {
    ;(async function loadScripsAsync() {
      await loadScript("/scripts/jquery.min.js", { inBody: true })
      await loadScript("/scripts/bootstrap.min.js", { inBody: true })
      await loadScript("/scripts/plugins.min.js", { inBody: true })
      await loadScript("/scripts/main-scripts.js", { inBody: true })
    })()
  }, [])

  return (
    <>
      <SEO
        pathname={location.pathname}
      />
      <Preloader />
      <Header />
      <Sidenav data={prismicSidenav.data} locale={locale} />
      <div id="site-wrapper">
        <Hero data={prismicHero.data} />
        <About data={prismicAbout.data} />
        <Resume data={prismicResume.data} />
        {/* <Services />
        <Testimonials /> */}
        <Blog locale={locale} data={allPrismicPost.edges} />
        <Contact data={prismicContact.data} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    prismicSidenav(lang: { eq: $locale }) {
      data {
        testimonials {
          text
        }
        services {
          text
        }
        resume {
          text
        }
        home {
          text
        }
        contact {
          text
        }
        blog {
          text
        }
        about {
          text
        }
      }
    }
    prismicHero(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        jobs {
          title1 {
            text
          }
        }
        i_am_a {
          text
        }
        button_secondary {
          text
        }
        button_primary {
          text
        }
        based_in {
          text
        }
      }
    }
    prismicAbout(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        description {
          html
        }
        skills {
          title1 {
            text
          }
          description1 {
            html
          }
          icon {
            text
          }
        }
      }
      lang
    }
    prismicResume(lang: { eq: $locale }) {
      data {
        tech {
          item {
            text
          }
        }
        experience {
          company {
            text
          }
          description {
            html
          }
          dates {
            text
          }
          icon {
            text
          }
          legend {
            text
          }
        }
        education {
          name {
            text
          }
          legend {
            text
          }
          icon {
            text
          }
          description {
            html
          }
          dates {
            text
          }
        }
        title {
          text
        }
        subtitle {
          text
        }
        title_experience {
          text
        }
        title_education {
          text
        }
      }
    }
    allPrismicPost(filter: {lang: {eq: $locale}}) {
      edges {
        node {
          data {
            date(formatString: "ll")
            title {
              text
            }
            image_header {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 300)
                }
              }
            }
          }
          uid
        }
      }
    }
    prismicContact(lang: { eq: $locale }) {
      data {
        your_name {
          text
        }
        your_message {
          text
        }
        your_email {
          text
        }
        title {
          text
        }
        subtitle {
          text
        }
        description {
          html
        }
        submit_button {
          text
        }
      }
    }
  }
`

export default IndexPage
